import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'
import { getValueOnPath } from '@/js/utils/utils'
import { sampleSize } from 'lodash'
import Sounds from '@/js/utils/sounds'



// === STATE =====================================================================================
const state = {
	current_client_game: null,
	progression: null,
	step: null,
	loot_rewards: null,
	final_score: 0,
	player_score: 0,
	opponent_score: 0,
	simulator: null,
	ui: {
		matching_error: null,
		zoomed_slide: null
	}
}








// === GETTERS =====================================================================================
const getters = {
	game(state) { return state }
}







// === ACTIONS =====================================================================================
const actions = {

	// Lance une partie et récupère l'étape en cours
	launch_game: async ({ commit, rootGetters }, data) => {
		const games = getValueOnPath(rootGetters.world, 'current.city.clientGames')
		if (games && games.length) {
			commit('EDIT_GAME_STATE', { current_client_game: games.find(g => g.id === data.game_id) })
		}
		// const result = await Apollo.mutate('CREATE_CHALLENGE', { progressionId: '6203d69f6955920009cd076c' })
		// console.log(result)
		let result = null
		if (data.is_challenge)
			result = await Apollo.mutate('CREATE_PROGRESSION_CHALLENGE', { gameId: data.game_id })
		else
			result = await Apollo.mutate('FIND_OR_CREATE_PROGRESSION', { gameId: data.game_id })
		if (result && result.currentMark && result.currentMark.step) {
			const simulator = getValueOnPath(result.currentMark.step, 'simulator')
			commit('EDIT_GAME_STATE', { progression: result, step: result.currentMark.step, simulator, loot_rewards: null, final_score: 0, player_score: 0, opponent_score: 0 })
		}
	},



	// Termine une étape puis passe à la suivante
	complete_current_step: async ({ commit, state, dispatch }) => {
		const result = await Apollo.mutate('COMPLETE_STEP', { progressionId: state.progression.id })
		if (result && result.progression && result.nextStep && result.nextStep.content) {
			commit('EDIT_GAME_STATE', { progression: result.progression, step: result.nextStep.content, final_score: result.score, time: (result.time || null), loot_rewards: (result.lootRewards || null) })
			if (result.nextStep.content.layout === 'result') dispatch('get_user')
		}
	},



	// Répond à une question puis passe à l'étape suivante
	trivia_send_answer: async ({ state, dispatch }, data) => {
		return await Apollo.mutate('SEND_ANSWER', {
			progressionId: state.progression.id,
			questionId: data.question_id,
			answers: data.answers_id,
			time: data.time ? data.time : 0
		})
	},



	draw_matching_elements: ({ state, commit }) => {
		let sets = getValueOnPath(state, 'step.sets.data')

		// On défini l'ordre des élements aléatoirement si ce n'est pas déjà fait
		let orders = []
		let i = 0
		sets.forEach((set) => {
			let matchable_elements = getValueOnPath(set, 'matchableElements')
			matchable_elements.forEach((matchable_element) => { orders.push(++i) })
		})
		sets.forEach((set) => {
			let matchable_elements = getValueOnPath(set, 'matchableElements')
			matchable_elements.forEach((matchable_element) => {
				if (!matchable_element.order) {
					let index = Math.floor(Math.random() * orders.length)
					commit('EDIT_MATCHABLE_ELEMENT', { matchable_element: matchable_element, order: orders[index] })
					orders.splice(index, 1)
				}
			})
		})

		// On passe les sets terminés et visibles comme non visibles
		sets.forEach((set) => {
			let matchable_elements = getValueOnPath(set, 'matchableElements')
			if (matchable_elements.filter((matchable_element) => { return matchable_element.checked }).length === matchable_elements.length) {
				matchable_elements.forEach((matchable_element) => {
					if (matchable_element.visible) commit('EDIT_MATCHABLE_ELEMENT', { matchable_element: matchable_element, visible: false })
				})
			}
		})

		// On récupère les sets non terminés
		let undone_sets = sets.filter((set) => {
			let matchable_elements = getValueOnPath(set, 'matchableElements')
			return matchable_elements.filter((matchable_element) => { return !matchable_element.checked }).length === matchable_elements.length
		})

		// On tire au hasard les sets à faire et on les rend visibles
		let visible_sets = sampleSize(undone_sets, 4)
		visible_sets.forEach((set) => {
			let matchable_elements = getValueOnPath(set, 'matchableElements')
			matchable_elements.forEach((matchable_element) => {
				commit('EDIT_MATCHABLE_ELEMENT', { matchable_element: matchable_element, checked: false, active: false, visible: true })
			})
		})
	},


	toggle_active_matchable_element: async ({ commit }, data) => {
		if (data.matchable_element && !data.matchable_element.checked) {
			commit('EDIT_MATCHABLE_ELEMENT', { matchable_element: data.matchable_element, active: !data.matchable_element.active })
		}
	},



	matching_send_match: async ({ state, commit }, data) => {
		if (data.matchable_elements) {

			let matchable_element_ids = data.matchable_elements.map((m) => { return m.id })

			// Récupération du set avec toutes les bonnes réponses (sinon erreur)
			let sets = getValueOnPath(state, 'step.sets.data')
			let valid_set = sets.find((set) => {
				let elements = getValueOnPath(set, 'matchableElements')
				let nb_valid = elements.filter((matchable_element) => { return matchable_element_ids.includes(matchable_element.id) }).length
				if (nb_valid === elements.length) return true
				return false
			})

			// Modification de l'objet pour affichage
			data.matchable_elements.forEach((matchable_element) => {
				if (valid_set) {
					Sounds.play('success')
					commit('EDIT_MATCHABLE_ELEMENT', { matchable_element, checked: true, active: false })
				} else {
					Sounds.play('failure')
					commit('EDIT_MATCHABLE_ELEMENT', { matchable_element, checked: false, error: Date.now(), active: false })
					commit('EDIT_GAME_UI_STATE', { matching_error: Date.now() })
				}
			})

			// Appel Apollo pour sauvegarder les données
			await Apollo.mutate('SEND_MATCH', {
				progressionId: state.progression.id,
				matchableElementIds: matchable_element_ids,
				time: data.time ? data.time : 0
			})
		}
	},


	clear_game({ commit }, data) {
		commit('EDIT_GAME_STATE', { progression: false, player_score: 0, opponent_score: 0 })
	},

	edit_game_ui({ commit }, data) {
		commit('EDIT_GAME_UI_STATE', data)
	}


}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_GAME_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	EDIT_GAME_CURRENT(state, data) {
		for (var i in data) {
			Vue.set(state.current, i, data[i])
		}
	},

	EDIT_GAME_UI_STATE(state, data) {
		for (var i in data) {
			Vue.set(state.ui, i, data[i])
		}
	},

	EDIT_MATCHABLE_ELEMENT(state, data) {
		for (var i in data) {
			if (i != 'matchable_element') Vue.set(data.matchable_element, i, data[i])
		}
	},


}

export default { state, getters, actions, mutations }
