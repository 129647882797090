import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloLink } from "apollo-link"
import { purify } from '@/js/utils/utils'
import VueApollo from 'vue-apollo'
import introspectionQueryResultData from '@/apollo/fragmentTypes.json'
import store from '@/store/store'
import { REFRESH_TOKEN } from '@/js/factory/refreshToken'
import { CLIENT } from '@/js/factory/client'
import { LANGUAGE as LANG } from '@/js/factory/language'
import { cloneDeep } from 'lodash'
import {getValueOnPath} from "../js/utils/utils"

let isRefreshToken = false

const AUTH_TOKEN = 'Authorization'
const CLIENT_ID = CLIENT.getDefinition()
CLIENT.setData({ data: process.env.VUE_APP_APPOLO_STACK_CLIENT })
const LANGUAGE = LANG.getDefinition()

const httpEndpoint = process.env.VUE_APP_APOLLO_BASE_URL
const httpLink = new HttpLink({
  uri: httpEndpoint,
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const authLink = setContext((_, {headers}) => {
  return {
    headers: {
      [AUTH_TOKEN]: isRefreshToken
        ? REFRESH_TOKEN.getData()
        : store.getters.accessToken,
      [CLIENT_ID]: CLIENT.getData() || '',
      [LANGUAGE]: 'fr',
    }
  }
})

const link = ApolloLink.from([ authLink, httpLink ])

const defaultOptions = {
  httpLinkOptions: {
    credentials: 'same-origin',
  },
  httpEndpoint,
  wsEndpoint: null,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  defaultHttpLink: false,
  link,
  cache: new InMemoryCache({
    fragmentMatcher
  }),
}

function createProvider(options = {}) {
  const {apolloClient, wsClient} = createApolloClient({
    ...defaultOptions,
    ...options,
  })

  apolloClient.wsClient = wsClient

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        loadingKey: 'loading',
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })
  return apolloProvider
}


const apolloClient = createProvider().defaultClient


const Apollo = {}

Apollo.query = async function(query_name, variables, onError = () => {}) {
  let t = Date.now()
  store.commit('SET_LOADING', { loading: true })

  let params = {
    fetchPolicy: 'network-only',
    query: require('@/apollo/queries/' + query_name + '.gql')
  }
  if (variables) params.variables = variables

  const request = await apolloClient.query(params).catch(error => {
    onError(error)
    return error
    // console.error(error)
    // store.dispatch('add_notification', { status: 'error', message: error.toString().replace('Error: GraphQL error: ', '') })
  })

  store.commit('SET_LOADING', { loading: false })

  return purify(cloneDeep(getValueOnPath(request, 'data.response')))
}

Apollo.refreshToken = async function() {
  isRefreshToken = true
  const { data: { response = null } = {} } = await apolloClient.mutate({
    mutation: require('@/apollo/mutations/REFRESH_CLIENT_USER_TOKEN.gql')
  }).catch(x => false)
  isRefreshToken = false
  return response
    ? purify(_.cloneDeep(response))
    : false
}

Apollo.mutate = async function(mutation_name, variables, onError = () => {}) {
  let t = Date.now()
  store.commit('SET_LOADING', { loading: true })

  let params = { mutation: require('@/apollo/mutations/' + mutation_name + '.gql') }
  if (variables) params.variables = variables

  const request = await apolloClient.mutate(params).catch(error => {
    onError(error)
    return error
    // console.error(error)
    // store.dispatch('add_notification', { status: 'error', message: error.toString().replace('Error: GraphQL error: ', '') })
  })

  store.commit('SET_LOADING', { loading: false })

  return purify(cloneDeep(getValueOnPath(request, 'data.response')))
}

export default Apollo
