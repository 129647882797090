import Vue from 'vue'
import Vuex from 'vuex'
import Sounds from '@/js/utils/sounds'

Vue.use(Vuex)

import app from './modules/app'
import user from './modules/user'
import world from './modules/world'
import game from './modules/game'
import login from './modules/login.module'
import { schema } from './modules/schema.module'

const store = new Vuex.Store({
	state: {},
	modules: {
		app,
		user,
		world,
		game,
		schema,
		login
	},
	strict: process.env.NODE_ENV !== 'production'
})

// Watch sounds volume
store.watch(
	() => { return app.state.settings.sounds },
	(newValue) => { Sounds.set_volume(newValue / 10) },
	{ immediate: true }
)

// Auto save settings
store.watch(
	() => { return app.state.settings },
	() => { store.dispatch('save_settings') },
	{ deep: true }
)

export default store
