import { Howl, Howler } from 'howler'

const Sounds = {
	elements: [],
	playings: [],
	volume: 0.5,

	init() {
		let start = Date.now()
		const files = require.context('@/assets/sounds/', true, /^.*\.mp3$/).keys()
		for (let id in files) {
			let slug = files[id].substr(2).replace('.mp3', '')
			this.elements.push({
				slug,
				howl: new Howl({
					src: [ require('@/assets/sounds/' + files[id].substr(2)) ],
					volume: 0.5,
					onend: () => {
						const index = this.playings.findIndex(p => p.slug === slug)
						if (index > -1) this.playings.splice(index, 1)
					},
				})
			})
		}
		console.info(this.elements.length + ' sounds loaded in ' + (Date.now() - start) + 'ms')
	},

	play(slug) {
		if (this.volume) {
			const time = Math.trunc(Date.now() / 20)
			if (!this.playings.find(p => p.slug === slug && p.time === time)) {
				this.elements.find(e => e.slug === slug).howl.play()
				this.playings.push({ slug, time })
			}
		}
	},

	set_volume(volume) {
		this.volume = volume
		this.elements.forEach((element) => {
			element.howl.volume(volume)
		})
	},

	mute(mute) {
		Howler.mute(mute)
	}

}

export default Sounds