import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'

// === STATE =====================================================================================
const state = {
	loading: false,
	main_loader: true,
	online: true,
	device: 'mobile',
	performance: 'high',
	notifications: [],
	settings_visible: false,
	tuto: null,
	tutorials: [],
	tuto_user_data: null,
	leaderboard: null,
	settings: {
		sounds: 0,
	}
}

// === GETTERS =====================================================================================
const getters = {
	app(state) { return state },
}

// === ACTIONS =====================================================================================
const actions = {
	set_tuto({ commit }, data) {
		commit('SET_TUTO', data)
	},
	set_app({ commit }, data) {
		commit('EDIT_APP_STATE', data)
	},
	get_tutorials: async ({ dispatch }) => {
		let { data } = await Apollo.query('CLIENT_TUTORIAL_INDEX')
		if (data) {
			let newData = []
			for (let tuto of data) {
				const { info } = tuto
				const { routes } = info
				newData = [ ...newData, Object.assign({}, tuto, {
					info: Object.assign({}, info, {
						routes: await (async () => {
							let newRoutes = {}
							const keys = Object.keys(routes)
							for (let key of keys) {
								const route = routes[key]
								Object.assign(newRoutes, {
									[key]: await (async () => {
										let result = []
										for (let x of route) {
											const { formSlug: { id, slug } = {} } = x
											if (id) {
												const query = 'CLIENT_GAME_STEP_FORM'
												const variables = { id }
												const result = await Apollo.query(query, variables)
												if (result) {
													const { formElements } = result
													const {answers} = x
													if (answers && answers.length) {
														const {data: {key} = {}} = answers[0]
														if (key) {
															x.answers = answers
																.map((answer, index) => {
																	const formElement = formElements
																		.find(({entry: {slug} = {}}) => slug === key)
																	const {entry: {selectableElements} = {}} = formElement
																	const {id} = selectableElements[index]
																	answer.data.value = id
																	return answer
																})
														}
													}
												}
											}
											result = [ ...result, x ]
										}
										return result
									})()
								})
							}
							return newRoutes
						})()
					})
				})]
			}
			await dispatch('set_app', { tutorials: newData })
		}
	},
	edit_online({ commit }, data) {
		commit('EDIT_APP_STATE', { online: data.online })
	},
	add_notification({ commit, state }, data) {
		commit('ADD_NOTIFICATION', { notification: data })
		setTimeout(function() {
			commit('REMOVE_NOTIFICATION', { notification: data })
		}, 5000)
	},
	remove_notification({ commit }, data) {
		commit('REMOVE_NOTIFICATION', { notification: data.notification })
	},
	save_settings({ state }) {
		localStorage.setItem('oqlf-settings', JSON.stringify(state.settings))
	},
	load_settings({ state, commit }) {
		try {
			const data = JSON.parse(localStorage.getItem('oqlf-settings'))
			if (data) {
				commit('EDIT_APP_STATE', { settings: { ...state.settings, ...data } })
			}
		} catch (error) {
			console.error('Unable to load user', error)
		}
	},
}

// === MUTATIONS =====================================================================================
const mutations = {
	SET_TUTO(state, data) {
		Vue.set(state, 'tuto', null)
	},
	EDIT_APP_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},
	SET_LOADING(state, data) {
		Vue.set(state, 'loading', data.loading)
	},
	ADD_NOTIFICATION(state, data) {
		if (!data.notification.id) data.notification.id = Math.random().toString(36).substr(2, 9)
		state.notifications.push(data.notification)
	},
	REMOVE_NOTIFICATION(state, data) {
		var index = state.notifications.findIndex((n) => n == data.notification)
		if (index > -1) state.notifications.splice(index, 1)
	},
}

export default { state, getters, actions, mutations }
