import Vue from 'vue'
import store from './store/store'
import { medias } from './store/medias'
import App from './App.vue'
import router from './router'
import UiButton from '@/components/ui/ui-button'
import UiIcon from '@/components/ui/ui-icon'
import { VueHammer } from 'vue2-hammer'
import Scrollbar from '@/plugins/scrollbar'
import TouchPrevent from '@/plugins/touch-prevent'
import { extendVeeValidate } from './js/vee-validate/validators'
import StackMedias from './js/plugins/medias-plugin'
import {garbageCollector} from '@/js/utils/garbageCollector'
import {manageLottie} from '@/js/managers/manageLottie'

garbageCollector.add(manageLottie)

VueHammer.config.domEvents = false
Vue.config.productionTip = false

Vue.use(VueHammer)
Vue.use(Scrollbar)
Vue.use(TouchPrevent)
Vue.use(StackMedias, {
  dbName: 'mots-de-jeux',
  version: 1
})
Vue.component('ui-icon', UiIcon)
Vue.component('ui-button', UiButton)

Vue.prototype.$medias = medias

extendVeeValidate()

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
