<template>
	<div class="ui-icon" :style="{ width: size + 'px', height: size + 'px' }">
		<img :src="url">
	</div>
</template>

<script>
export default {
	name: 'ui-icon',
	data() {
		return {
			
		}
	},
	props: {
		name: { default: false },
		size: { default: 16 },
		tags: { default: Array }
	},
	computed: {
		url() {
			if (this.tags.length > 0) {
				return this.$medias.find({
					clientMedias: this.$medias.files,
					tags: this.tags
				})
			}
			else if (this.name) {
				return require('@/assets/img/' + this.name + '.svg')
			}
			return null
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.ui-icon
	display inline-block
	&.size-16
		width 16px
		height 16px
	img
		position absolute
		left 0
		top 0
		height 100%
		width 100%
		

</style>
