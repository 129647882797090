import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'
import { USER } from '@/js/factory/user'
import { manageSignUp } from '@/js/managers/manageSignUp'
import { manageUser } from '@/js/managers/manageUser'
import { manageTuto } from "@/js/managers/manageTuto"

const clientGameBy = 'CLIENT_GAME_BY'
const createProfile = 'createProfile'
const createProgression = 'CREATE_PROGRESSION'
const currentUser = 'CURRENT_USER'
const signUp = 'signup'
const start = 'start'
const login = 'login'

// === STATE =====================================================================================
const state = {
	needStart: true,
	manageTuto: new manageTuto({
		_params: {
			redirect: {
				tuto: {
					world: login
				}
			}
		}
	}),
	manageSignUp: new manageSignUp({
		_params: {
			createAnonymous: {
				query: clientGameBy,
				variables: { tags: signUp }
			},
			createProfile: {
				query: clientGameBy,
				variables: { tags: createProfile }
			},
			userStates: {
				is_anonymous: {
					mutation: createProgression,
					route: signUp
				},
				is_confirmed: {
					mutation: createProgression,
					route: signUp
				}
			},
			redirect: {
				onboarding: {
					is_anonymous: start,
					is_confirmed: start,
				}
			}
		}
	}),
	manageUser: new manageUser({
		_params: {
			queries: {
				currentUser,
			},
		},
	}),
}

// === GETTERS =====================================================================================
const getters = {
	user: function(state) { return state },
	user_gold: function(state) {
		if (state.inventory) return (state.inventory.find((i) => { return i.clientLootItem && i.clientLootItem.countable && i.clientLootItem.slug == 'gold' }) || { amount: 0 } ).amount
		return 0
	},
	manageUser: state => state.manageUser,
	manageSignUp: state => state.manageSignUp,
	manageTuto: state => state.manageTuto,
	progressionId: state => state.manageSignUp.progressionId,
	formStep: state => state.manageSignUp.formStep,
}

// === ACTIONS =====================================================================================
const actions = {
  setFormStep({ commit }, { step }) {
    commit('setFormStep', { step })
  },
	noNeedForStart({commit}) {
		commit('noNeedForStart')
	},
	setUserAuth({ commit }, payload) {
		commit('setUserAuth', payload)
	},

	set_user({ commit }, data) {
		commit('EDIT_USER_STATE', { ...data.user })
	},

	// Récupère les informations de l'utilisateur actuellement connecté
	get_user: async ({ dispatch }, data) => {
		const result = await Apollo.query('CURRENT_USER')
		if (result) await dispatch('set_user', { user: result })
	},

	// Supprime toute la progression de l'utilisateur
	reset_user: async ({ dispatch }) => {
		await Apollo.mutate('RESET_TUTORIAL_JOURNAL')
		await Apollo.mutate('RESET_USER')
		await dispatch('get_user')
	},

	update_tutorial: async ({ dispatch }, data) => {
		await Apollo.mutate('EDIT_TUTORIAL_JOURNAL', { tutorialId: data.tuto, status: data.status })
		await dispatch('get_user')
	},

	removeUser: () => {
		USER.clearData()
	},

	// Débloquer une ville en payant son montant en gold
	unlock_city: async ({ dispatch }, data) => {
		let criteria = data.city.clientCriterias.find((c) => { return c.action == 'spend' && c.clientLootItem && c.clientLootItem.countable  && c.clientLootItem.slug == 'gold' })
		if (criteria) {
			const result = await Apollo.mutate('COMPLETE_CRITERIA', { criteriaId: criteria.id })
			if (result && result.userChanged) dispatch('set_user', { user: result.user })
		}
	}
}

// === MUTATIONS =====================================================================================
const mutations = {
	setFormStep(state, { step }) {
		Vue.set(state.manageSignUp, 'step', step)
	},
	progressionId(state, { progressionId }) {
		Vue.set(state.manageSignUp, 'progressionId', progressionId)
	},
	noNeedForStart(state) {
		state.needStart = false
	},
	setUserAuth(state, { value: data }) {
		if (!data) return
		USER.setData({ data })
	},
	EDIT_TUTORIAL_JOURNAL(state, data) {
		for (var i in data) {
			if (i !== 'tuto') Vue.set(data.tuto, i, data[i])
		}
	},
	EDIT_USER_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	}
}

export default { state, getters, actions, mutations }
