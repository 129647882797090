import Apollo from '@/apollo/apollo-client'
import Vue from 'vue'
import {base64toBlob} from "../js/utils/utils"

const medias = {
  files: [],
  result: [],
  async init({ loadPrefetch = false }) {
    const SVG = 'svg'
    const BLOB = 'blob'
    medias.result = medias.result.length
      ? medias.result
      : (await Apollo.query('CLIENT_MEDIA_INDEX_NO_URL', { limit: 500 }) || {}).data || []
    const foreignMedias = medias.result || []
    await Vue.mDeleteObsolete({ foreignMedias }) // TODO maybe remove and dispose at another place
    const localMedias = await Vue.mGetLocalMedias()
    const mustFetch = localMedias.length
      ?	foreignMedias.reduce((a, media) => {
        const localUpdatedAt = (localMedias.find(x => x.id === media.id) || {}).updatedAt
        if (!localUpdatedAt || localUpdatedAt !== media.updatedAt) {
          return [...a, media]
        }
        return a
      }, [])
      : foreignMedias
    if (!mustFetch.length) return await this.noChange({ localMedias })
    const loading = document.querySelector('#loading-images')
    const loading_text = document.querySelector('#loading-images .text')
    const loading_progress = document.querySelector('#loading-images .progress')
    let counter = 0
    const ids = mustFetch
      .filter(({ preload }) => preload)
      .filter(({ prefetch }) =>
        loadPrefetch && prefetch
          ? true
          : !loadPrefetch && !prefetch
      )
      .map(x => x.id)
    // TODO remove those lines
    // const ids = mustFetch
    //   .reduce((a, c) => {
    //     const { document, id } = c
    //     if (document) {
    //       const { prefetch = false } = document
    //       if (prefetch && loadPrefetch) return [ ...a, id ]
    //       if (!prefetch && !loadPrefetch) return [ ...a, id ]
    //     } else if (!loadPrefetch) {
    //       return [ ...a, id ]
    //     }
    //     return a
    //   }, [])
    const total = ids.length
    if (loadPrefetch && total) {
      if (loading) loading.style.display = 'block'
      if (loading_text) loading_text.innerHTML = 'Chargement des images... ' + counter + '/' + total
    }
    let promises = []
    const fetched = ids.length
      ? (await Apollo.query('CLIENT_MEDIA_INDEX', { limit: 500, ids }) || {}).data || []
      : []
    for (let file of fetched) {
      promises.push(
        fetch(file.url)
          .then(async response => {
            const { document } = file
            const asSVG = document
              ? document.asSVG
              : false
            return new Promise(async (resolve) => resolve({
              type: asSVG ? SVG : BLOB,
              content: await response[asSVG ? 'text' : 'blob']()
            }))
          })
          .then(({ type, content }) => {
            const { updatedAt, id, slug, url, tags, document, contentType } = file
            const isSVG = type === SVG
            return {
              updatedAt,
              id,
              slug,
              url,
              tags,
              document,
              contentType,
              svg: isSVG,
              data: content,
            }
          }).then(x => {
          if (loadPrefetch && total) {
            counter += 1
            if (loading_text) loading_text.innerHTML = 'Chargement des images... ' + counter + '/' + total
            if (loading_progress) loading_progress.style.width = Math.round(counter * 100 / total) + '%'
          }
          return x
        })
          .catch(err => {
            console.log(err.message)
          })
      )
    }

    const changes = await Promise.all(promises)
    const updates = await Promise.all(changes.map(async file => {
      const reader = new FileReader()
      const { data, svg } = file
      if (svg) {
        return Object.assign(file, {
          content: data
        })
      }
      const base64 = await new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.readAsDataURL(data)
      })
      delete file.data
      const content = URL.createObjectURL(base64toBlob(base64))
      return Object.assign(file, {
        base64,
        content
      })
    }))
    const actualMedias = localMedias.map(file => {
      const { svg, base64 } = file
      return svg
        ? file
        : Object.assign(file, {
          content: URL.createObjectURL(base64toBlob(base64))
        })
    })
    const object = actualMedias
      .reduce((a, c) => Object.assign(a, {
        [c.id]: c
      }), {})
    updates
      .forEach(x => {
        Object.assign(object, {
          [x.id]: x
        })
      })
    medias.files = [ ...medias.files || [], ...Object
      .keys(object)
      .map(key => object[key]) ]
    await Vue.mUpdate({ changes })
  },
  async noChange({ localMedias }) {
    medias.files = await localMedias
      .map(file => {
        const { base64, content, svg } = file
        return Object.assign(file, {
          content: svg ? content : URL.createObjectURL(base64toBlob(base64))
        })
      })
  },
  get({ id }) {
    if (!id) return null
    return (medias.files.find((f) => f.id === id) || {}).content
  },
  find({ clientMedias = medias.files, tags = [] }) {
    if (!tags.length) return null
    return this.get({
      id: (clientMedias.find(x => tags.every(y => (x.tags || []).includes(y))) || {}).id } || {})
  },
  findAllByTags({ clientMedias = medias.files, tags = [] }) {
    if (!tags.length) return null
    return clientMedias.filter(x => tags.every(y => (x.tags || []).includes(y)))
  },
  findBySlug({ clientMedias = medias.files, slug }) {
    if (!slug) return null
    return (clientMedias.find((f) => f.slug === slug) || {}).content
  }
}

export { medias }
