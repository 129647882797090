import Apollo from '@/apollo/apollo-client'
import JWTWorker from 'worker-loader!@/js/web-workers/steps.worker'
import store from '@/store/store'

const getInterval = minutes => minutes * 60000
const JWT = new JWTWorker
JWT.onmessage = async function({ data }) {
  await refreshClientUserToken()
}

JWT.postMessage({ cmd: 'setInterval', data: getInterval(25) })
JWT.postMessage({ cmd: 'neverEnd' })

export const JWTStop = () => JWT.postMessage({ cmd: 'stop' })
export const JWTStart = () => JWT.postMessage({ cmd: 'start' })

const confirm = async function({ accessToken, refreshToken }) {
  if (!accessToken || !refreshToken) return false
  JWTStart()
  await store.dispatch('loginSuccessful', { accessToken, refreshToken })
  return accessToken
}

export const login = async function({ email, password }) {
  const { accessToken = null, refreshToken = null } = await Apollo.mutate('LOG_IN_CLIENT_USER', { email, password }) || {}
  return await confirm({ accessToken, refreshToken })
}

export const refreshClientUserToken = async function() {
  const { accessToken = null, refreshToken = null } = await Apollo.refreshToken() || {}
  return await confirm({ accessToken, refreshToken })
}

export const createAnonymousUser = async function() {
  const { accessToken = null, refreshToken = null } = await Apollo.mutate('CREATE_ANONYMOUS_USER') || {}
  return  await confirm({ accessToken, refreshToken })
}

export const removeAccessToken = async function() {
  await store.dispatch('removeAccessToken')
}
