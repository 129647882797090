<template>
	<div id="app" :class="[ app.device, 'perf-' + app.performance, touch_class ]">

		<div v-if="ready" id="main" ref="main">
			<router-view />
			<transition name="tuto">
				<tuto v-if="app.tuto" :tuto="app.tuto" v-on:end="end_tuto" v-on:skip="end_tuto" />
			</transition>
		</div>

		<transition-group name="notifications" class="notifications" tag="div">
			<ui-notification v-for="notification in app.notifications" :key="notification.id" :notification="notification" />
		</transition-group>

		<div v-if="app.main_loader" class="main-loader">
			<div id="loading-images">
				<div class="text"></div>
				<div class="bar"><div class="progress"></div></div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiNotification from '@/components/ui/ui-notification'
import Feature from 'feature.js'
import Tuto from '@/components/tuto'
import { manageEventListeners } from "@/js/managers/manageEventListeners"
import Sounds from "@/js/utils/sounds"

export default {
	name: 'App',
	data() {
		return {
			ready: false,
			height: false,
			manageEventListeners: manageEventListeners(),
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		device() {
			// if (Feature.touch) return 'mobile'
			if (window.innerWidth < 663) return 'mobile'
			return 'desktop'
		},
		touch_class() {
			if (Feature.touch) return 'touch'
			else return 'not-touch'
		}
	},
	methods: {
		end_tuto() {
			this.$store.dispatch('update_tutorial', { tuto: this.app.tuto.name, status: 'done' })
			this.$store.commit('EDIT_APP_STATE', { tuto: null })
		},
		resize() {
			let main = this.$refs.main
			if (main) {
				let device = 'mobile'
				if (main.clientWidth > 1200) device = 'desktop'
				else if (main.clientWidth > 800) device = 'tablet'
				this.height = main.clientHeight
				if (device != this.app.device) this.$store.commit('EDIT_APP_STATE', { device })
			}
		}
	},
	watch: {
		ready() {
			this.manageEventListeners.destroy()
			this.$store.dispatch('get_cities')
			this.$nextTick(() => {
				this.resize()
				this.manageEventListeners.add(window, 'resize', this.resize)
			})
		}
	},
	async created() {
		await this.$medias.init({ loadPrefetch: true })
		Sounds.init()
		this.$store.dispatch('load_settings')
		this.ready = true
	},
	components: { UiNotification, Tuto }
}
</script>

<style lang="stylus">
@import './assets/css/variables'
@import './assets/css/reset'
@import './assets/css/vuebar'
@import './assets/css/city_color'

// @import url('https://fonts.googleapis.com/css?family=Cabin:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Cabin:400,500,700&display=swap');
@font-face
	font-family MonoSpatial
	font-style normal
	src url(./assets/fonts/MonoSpatial.ttf)

body
	font-family 'Cabin', sans-serif
	color dark
	font-weight 400
	background-color #222

#app
	font-size 1.6rem

#app.mobile
	font-size 1.4rem
	// #main
	// 	position absolute
	// 	left 50%
	// 	top 50%
	// 	transform translate(-50%, -50%)
	// 	width 375px
	// 	height 665px
	// 	background-color #ccc

.main-loader
	position fixed
	left 0
	top 0
	right 0
	bottom 0
	background dark url(./assets/img/main-loader.gif) center center no-repeat
	background-size 500px auto
	color lighten(dark, 50%)
	z-index 3
	#loading-images
		display none
		position absolute
		left 0
		right 0
		bottom 24px
		text-align center
		font-size 1.2rem
		font-weight 500
		text-transform uppercase
		line-height 16px
		.text
			margin 0 0 8px 0
		.bar
			display block
			margin 0 auto
			height 4px
			width 250px
			background-color darken(dark, 25%)
			border-radius 4px
			overflow hidden
			.progress
				position absolute
				left 0
				top 0
				height 100%
				background-color yellow
				transition width 0.1s linear

.notifications
	position fixed
	right 16px
	top 16px
	z-index 5000


</style>
